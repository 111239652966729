import React, { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Crop } from 'react-image-crop';
import { Box } from '@mui/material';

import {
  Constants,
  decodeInteger,
  encodeInteger,
  formatDate,
  LatestEventsDocument,
  Runsheet,
  useDeleteEventMutation,
} from '@checkpoints/shared';

import { styled } from '../../colors';
import {
  AddButton,
  DeleteButton,
  WhiteContainedButton,
} from '../../components/button';
import { DashedContainer } from '../../components/dashed-container';
import {
  DropzoneProps,
  EventImageDropzone,
} from '../../components/image-cropper';
import { ResizeDialogRaw } from '../../components/modals';
import { ServerImage } from '../../components/server-image';
import {
  DateField,
  TextFieldWithCharacterCount,
  TimeField,
} from '../../components/textfield';
import { TooltipWrapper } from '../../components/tooltip';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';
import { DeleteIcon } from '../../components/common';
import { formatBytes } from '../../utils/dropUploader';

import { AddModalType, DashboardStore } from './DashboardStore';
import { Header2, Header3 } from './DashboardHeader';
import { UserRow } from './DashboardRows';

export const EditEvent = observer(function EditEvent() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const { eventId } = useParams();

  const Id = decodeInteger(eventId);

  useEffect(() => {
    if (eventId) {
      DashboardStore.store.setActiveEventId(Id);
    }
  }, [Id, eventId]);

  const isEdit = !!Id;

  useEffect(() => {
    if (!isEdit && !MainStore.store.hasActivePaidAccount) {
      MainStore.store.displayError(Constants.ERRORS.CREATE_EVENT_NO_PAID_PLAN);
      navigate('/dashboard');
    }
  }, [isEdit, navigate]);

  return (
    <OuterWrapper>
      <StyledCreateEvent className="fadeInFromAbove">
        <FirstSection setStep={setStep} isEdit={isEdit} />
        {isEdit ? (
          <>
            <div>
              <Users isEdit={isEdit} />
            </div>
            <div>
              <EventControlSection />
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className={step < 2 ? 'entityDisabled' : 'entityEnabled'}>
              <Users setStep={setStep} isEdit={isEdit} />
            </div>
            <div className={step < 3 ? 'entityDisabled' : 'entityEnabled'}>
              <RunsheetSection />
            </div>
          </>
        )}
      </StyledCreateEvent>
    </OuterWrapper>
  );
});

const padding = '4%';

const OuterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;

  &.column {
    flex-direction: column;
    align-items: flex-end;
  }

  button {
    font-weight: 700;
    &.cancel {
      background: transparent;
      color: white;
      border: 1px solid white;
      font-weight: 400;
    }
  }
`;

const StyledCreateEvent = styled('div')`
  display: flex;
  margin: -${padding};
  margin-top: -2%;
  /* TODO: Calculate header height */
  height: calc(100vh - 160px);

  /* height: calc(100vh - 256px); */

  width: 100%;
  flex: 1 1 auto;

  > div {
    width: 33.3333%;
    flex: 1 1 auto;
    padding: ${padding};
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: auto;
    > div {
      width: 100%;
    }
    .user_list {
      height: 500px;
    }
  }
`;

const Users = observer(function Users({
  setStep,
  isEdit,
}: {
  isEdit: boolean;
  setStep?: (step: number) => void;
}) {
  const activeEvent = DashboardStore.store.activeEvent;
  const admins = DashboardStore.store.admins || [];
  const subscribers = DashboardStore.store.subscribers || [];

  return (
    <>
      <Header2>Add Crew members</Header2>
      <StyledUserList className="user_list">
        <Header3>{`Admins:`}</Header3>
        <DashedContainer full={false} reloadData={admins}>
          {admins.map((a) => (
            <UserRow
              key={a.id}
              user={a as any}
              onSettingsClicked={(user, event) => {
                DashboardStore.store.activateUserPopoverSettings({
                  anchorEl: event.target,
                  user: user,
                  isAdmin: true,
                });
              }}
              isOwner={a.id === activeEvent.owner.id}
            />
          ))}
          <AddButton
            onClick={() => {
              DashboardStore.store.showRunsheetModal(AddModalType.Admin);
            }}
          />
        </DashedContainer>
        <Header3>{`Subscribers:`}</Header3>
        <DashedContainer full={false} reloadData={subscribers}>
          {subscribers.map((a) => (
            <UserRow
              key={a.id}
              user={a as any}
              onSettingsClicked={(user, event) => {
                DashboardStore.store.activateUserPopoverSettings({
                  anchorEl: event.target,
                  user: user,
                  isAdmin: false,
                });
              }}
              isOwner={a.id === activeEvent.owner.id}
            />
          ))}
          <AddButton
            onClick={() => {
              DashboardStore.store.showRunsheetModal(AddModalType.Subscriber);
            }}
          />
        </DashedContainer>
        <ButtonContainer style={{ marginTop: '24px' }}>
          {/* {!isEdit && ( */}
          <Button
            variant="contained"
            className={isEdit ? 'entityDisabled' : 'entityEnabled'}
            onClick={() => setStep(3)}
          >
            {DashboardStore.store.touchedUsers
              ? `Continue`
              : `I'll do this later`}
          </Button>
          {/* )} */}
        </ButtonContainer>
      </StyledUserList>
    </>
  );
});

const StyledUserList = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    /* margin-top: 20px; */
    /* text-align: center; */
  }
  button {
    align-self: center;
  }
`;

const FirstSection = observer(function FirstSection({
  setStep,
  isEdit = false,
}: {
  setStep?: (step: number) => void;
  isEdit: boolean;
}) {
  const mainStore = MainStore.store;
  const activeEvent = DashboardStore.store.activeEvent;
  const [title, setTitle] = useState(activeEvent ? activeEvent.title : '');
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (activeEvent) {
      setTitle(activeEvent.title);
    }
  }, [activeEvent]);

  const createEventButtonTitle = isEdit
    ? `Update event`
    : activeEvent
    ? `Continue`
    : `Create Event`;

  const [file, setFile] = useState<File>();

  const onCrop = async (crop: Crop) => {
    console.log('crop', crop);
    console.log('file', file);

    if (crop && file) {
      console.log('cropped', crop);

      DashboardStore.store.updateEvent(title, file, crop).then(() => {
        setFile(null);
      });
    } else {
      setFile(null);
    }
  };

  const onDropFiles: DropzoneProps['onDrop'] = (files, rejected) => {
    console.log('files', files);

    if (rejected.length) {
      rejected[0].errors.forEach((err) => {
        let message = err.message;
        const bytesRegex = /(\d+)\sbytes/;
        const match = message.match(bytesRegex);

        if (match) {
          const bytes = parseInt(match[1]);
          const formattedBytes = formatBytes(bytes);
          message = message.replace(bytesRegex, formattedBytes);

          console.log(message);
        }

        mainStore.displayNotification({
          message: message,
          options: { variant: 'error', timeout: 6000 },
        });
        throw new Error(message);
      });
    }
    if (files.length) {
      setFile(files[0]);
    }
  };

  const onCreateEvent = () => {
    DashboardStore.store
      .updateEvent(title)
      .then(() => {
        if (isEdit) {
          MainStore.store.displayNotification({
            message: 'Event updated!',
          });
        }
        setStep(2);
      })
      .catch();
  };

  const showEventImage = title || activeEvent;

  return (
    <div className={cx(classes.BasicSettings)}>
      <form autoComplete="off">
        <Header2>{isEdit ? `Update ${title}` : 'Create new Event'}</Header2>
        <TextFieldWithCharacterCount
          characterCountValue={30}
          value={title}
          placeholder={'Name of your event'}
          label="Name of your event"
          name="title"
          preventEnter={true}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          inputProps={{
            autoComplete: 'event_name',
          }}
        />
      </form>
      <ResizeDialogRaw file={file} open={!!file} onClose={onCrop} />
      {showEventImage && (
        <>
          {activeEvent && activeEvent.image && (
            <StyledImageHolder>
              <ServerImage src={activeEvent.image.thumbnail} />
              <div className="image_hint">
                <button
                  onClick={async () => {
                    const result = await ModalStore.store.confirm(
                      'Are you sure you want to remove the image from this event? This action cannot be reversed.',
                    );

                    if (result) {
                      await DashboardStore.store.removeImage();
                    }
                  }}
                >
                  <DeleteIcon />
                  Remove image
                </button>
              </div>
            </StyledImageHolder>
          )}
          <EventImageDropzone className="fadeIn" onDrop={onDropFiles} />
          <ButtonContainer className="fadeIn">
            <Button variant="contained" onClick={onCreateEvent}>
              {createEventButtonTitle}
            </Button>
          </ButtonContainer>
        </>
      )}
    </div>
  );
});

const StyledImageHolder = styled('div')`
  position: relative;
  overflow: hidden;
  .image_hint {
    button {
      cursor: pointer;
      opacity: 0;
      transition: all 0.1s ease-in-out;
      transition-property: opacity, transform;
      background: rgba(0, 0, 0, 0.6);
      padding: 10px;
      color: white;
      border: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }
  &:hover button {
    opacity: 1;
  }
`;

const initialDate = formatDate(new Date(), 'yyyy-MM-dd');
const initialRunsheet: Partial<Runsheet> = {
  title: '',
  startTime: '00:00:00',
  startDate: initialDate,
};

function RunsheetSection() {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();

  const [runsheet, setRunsheet] = useState<Partial<Runsheet>>(initialRunsheet);

  const onChange = useCallback((name: string, value: string) => {
    console.log('onChange', name, value);
    setRunsheet((local) => ({
      ...local,
      [name]: value,
    }));
  }, []);

  const createRunsheet = async () => {
    const result = await DashboardStore.store.createRunsheet({
      ...(runsheet as Runsheet),
      eventId: DashboardStore.store.activeEventId,
    });
    setRunsheet(initialRunsheet);
    navigate(`/dashboard/runsheets/${encodeInteger(result.id)}`);
  };

  return (
    <div className={cx(classes.BasicSettings)}>
      <Header2>Create your first Runsheet</Header2>
      <div>
        <TextFieldWithCharacterCount
          characterCountValue={30}
          value={runsheet.title}
          placeholder={'Name of your runsheet'}
          label="Name of your runsheet"
          onChange={(e) => {
            onChange('title', e.target.value);
          }}
          inputProps={{
            autoComplete: 'runsheet_name',
          }}
        />
      </div>
      <div>
        <DateField
          label="Date"
          value={runsheet.startDate}
          onChange={(val) => {
            onChange('startDate', val);
          }}
        />
      </div>
      <div>
        <TimeField
          label="Time"
          value={runsheet.startTime}
          onChange={(time) => {
            onChange('startTime', time);
          }}
          onBlur={(e) => {
            let value = e.target.value;

            if (value.length === 5) {
              value = value + ':00';
            }
            onChange('startTime', value);
          }}
        />
      </div>
      <div className="spacer" />
      <ButtonContainer className="column">
        <Button
          variant="contained"
          style={{ marginBottom: 8 }}
          onClick={createRunsheet}
        >
          Get this party started!
        </Button>
        <Button
          className="cancel"
          variant="contained"
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Nah, Take me back to dashboard
        </Button>
      </ButtonContainer>
    </div>
  );
}

const EventControlSection = observer(function DeleteSection() {
  const navigate = useNavigate();
  const [del] = useDeleteEventMutation({
    refetchQueries: [{ query: LatestEventsDocument }],
  });
  const { classes, cx } = useStyles();

  const [archived, SetArchived] = useState(false);

  const me = MainStore.store.me;
  const activeEvent = DashboardStore.store.activeEvent;

  useEffect(() => {

    let archived_string = me.archived ?? '';

    let raw_archived = archived_string.split(',');

    SetArchived(raw_archived.indexOf(activeEvent.id.toString()) != -1)

  }, [])

  const handleDuplicateEvent = () => {
    DashboardStore.store
      .duplicateEvent()
      .then(() => {
        MainStore.store.displayNotification({
          message: 'Event duplicated!',
        });
      })
      .catch();
  };

  const handleArchiveEvent = (value: boolean) => {

    SetArchived(value);

    DashboardStore.store
    .archiveEvent(value)
    .then(() => {
        MainStore.store.displayNotification({
          message: value ? 'Event archived!' : 'Event unarchived.'
        });
    })
    .catch();
  };

  const handleDeleteEvent = async () => {
    const result = await ModalStore.store.confirm(
      'Are you sure you want to delete this event and all of its data?',
    );
    if (!result) return;

    const activeEvent = DashboardStore.store.activeEvent;

    del({
      variables: {
        id: activeEvent.id,
      },
    }).then(() => {
      runInAction(() => {
        DashboardStore.store.events.remove(activeEvent);
        DashboardStore.store.setActiveEventId(null);
      });
      navigate('/dashboard');
    });
  };

  if (!me || !activeEvent) {
    return;
  }

  const disabled = activeEvent.ownerId !== me.id;

  return (
    <div className={cx(classes.BasicSettings)}>
      <Header2 style={{ marginBottom: 58 }}>Event control</Header2>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Button
          disabled={disabled}
          variant="contained"
          sx={{ fontWeight: 700 }}
          className="spacer"
          onClick={handleDuplicateEvent}
        >
          Duplicate event
        </Button>
        <TooltipWrapper
          title="Only the owner can delete this event"
          show={disabled}
        >
          <DeleteButton
            disabled={disabled}
            className="spacer"
            onClick={handleDeleteEvent}
          >
            Delete event
          </DeleteButton>
        </TooltipWrapper>

        {archived &&
        <Button
          variant="contained"
          sx={{ fontWeight: 700 }}
          className="spacer"
          onClick={() => handleArchiveEvent(false)}
        >
          Unarchive event
        </Button>
        }
        {!archived &&
        <Button
          variant="contained"
          sx={{ fontWeight: 700 }}
          className="spacer"
          onClick={() => handleArchiveEvent(true)}
        >
          Archive event
        </Button>
        }

      </Box>
    </div>
  );
});

export const useStyles = makeStyles()(() => ({
  BasicSettings: {
    color: 'white',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    form: {
      flex: 1,
      margin: '10px 0',
    },
    '> div': {
      margin: '12px 0',
      '&:last-child': {
        marginBottom: '0',
      },
    },
  },
}));
